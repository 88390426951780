import React from "react";
import {  RouteComponentProps } from "react-router";
import ContentWrapper from "../../components/ContentWrapper";
import {CwizResponse} from "jobjump-types/Cwiz";
import CwizAPI, { CwizInfoType, CwizResult } from "../../data/Cwiz";
import { CwizStates, initalResults, isCwizState } from "./common";
import "./cwiz.css";
import CwizMain from "./Main";
import CwizPersonality from "./Personality";
import CwizSubjects from "./Subjects";
import CwizTertiary from "./Tertiary";
import qs from "query-string";
import CwizResults from "./Results";
import CwizCategory from "./Categories";
import CwizPictures from "./Pictures";
import CwizSkills from "./Skills";
import CwizValues from "./Values";
import CareerPreferenceAPI, { CareerPreference, CareerPrefKind } from "../../data/CareerPreference";
import CareerPrefsPage from "./CareerPrefs";
import { Col, Row } from "../../components/grid";

const Cwiz : React.FC<RouteComponentProps> = (props) => {
    const [response, setResponse] = React.useState<CwizResponse>(props.location.state || initalResults); 
    const [results, setResults] = React.useState<CwizResult[]>([]);
    const [careerPrefs, setCareerPrefs] = React.useState<CareerPreference[]>([]);
    const [currentState, setCurrentState] = React.useState<CwizStates>("home");
    const [categories, setCategories] = React.useState<CwizInfoType[]>([]);
    const [personalities, setPersonalities] = React.useState<CwizInfoType[]>([]);
    const [subjects, setSubjects] = React.useState<CwizInfoType[]>([]);
    const [tertiary, setTertiary] = React.useState<CwizInfoType[]>([]);
    React.useEffect(() => {
        CareerPreferenceAPI.getPreferences().then(setCareerPrefs);
    }, []);
    React.useEffect(() => {
        if (props.location.state) { return }
        CwizAPI.getCwizResponse().then((r) => {
            if (r) {
                setResponse(r);
                CwizAPI.getCwizResult(r).then(setResults);
            }
        });
    }, [props]);
    React.useEffect(() => {
        CwizAPI.getCwizSubjects().then(setSubjects);
    }, []);
    React.useEffect(() => {
        CwizAPI.getCwizPersonality().then(setPersonalities);
    }, []);
    React.useEffect(() => {
        CwizAPI.getCwizTertiary().then(setTertiary);
    }, []);
    React.useEffect(() => {
        CwizAPI.getCwizCategories().then(c => setCategories(c.filter(i => i.name !== "Printing and Paper" && i.name !== "Chemical and Materials" && i.name !== "Furniture and Wood")));
    }, []);
    React.useEffect(() => {
        const {currentState} = qs.parse(props.location.search);
        if (typeof currentState !== "string" || !isCwizState(currentState)) {
            return;
        }
        setCurrentState(currentState);
    }, [props.location.search])
    React.useEffect(() => {
        if (response.tertiary) {
            CwizAPI.getCwizResult(response).then(setResults);
        }
    }, [response]);
    const addCareer = React.useCallback((kind:CareerPrefKind, id:number) => 
        CareerPreferenceAPI.addCareer(kind, id).then(() => 
            CareerPreferenceAPI.getPreferences().then(setCareerPrefs)
        )
    , [setCareerPrefs]);
    const deleteCareer = React.useCallback((kind:CareerPrefKind, id:number) => 
        CareerPreferenceAPI.deleteCareer(kind, id).then(() => 
            CareerPreferenceAPI.getPreferences().then(setCareerPrefs)
        )
    , [setCareerPrefs]);
    const goToPage = (currentState:CwizStates, newResponse?:CwizResponse) => {
        props.history.push({pathname:props.location.pathname, search:qs.stringify({currentState}), state:newResponse || response});
    }
    const updateResponse = (r:CwizResponse, pageToGoTo?:CwizStates) => {
        setResponse(r);
        CwizAPI.saveCwizResponse(r);
        if (pageToGoTo) {
            goToPage(pageToGoTo, r);
            return;
        }
        if (r.tertiary) {
            goToPage("results", r);
        } else {
            goToPage("tertiary", r);
        }
    };
    const currentSubject = subjects.find(s => s.id === response.subject)?.name;
    const currentPersonality = personalities.find(p => p.id === response.personality)?.name;
    const currentCategory = categories.find(c => c.id === response.category)?.name;
    const currentTertiary = tertiary.find(t => t.id === response.tertiary)?.name;
    return <ContentWrapper title="Career Wiz Quiz" subtitle={["Find a career unique to you, in three simple steps."]}>
        {currentState === "home" && <CwizMain 
            {...{currentCategory,currentPersonality,currentSubject,currentTertiary, results, goToPage, careerPrefs, response, updateResponse}}
        />}
        {currentState === "categories" && <CwizCategory categories={categories} updateResponse={updateResponse} response={response} goToPage={goToPage} /> }
        {currentState === "pictures" && <CwizPictures updateResponse={updateResponse} response={response} goToPage={goToPage} /> }
        {currentState === "skills" && <CwizSkills categories={categories} updateResponse={updateResponse} response={response} goToPage={goToPage} /> }
        {currentState === "values" && <CwizValues updateResponse={updateResponse} response={response} goToPage={goToPage} /> }
        {currentState === "personality" && <CwizPersonality personalities={personalities} updateResponse={updateResponse} response={response} goToPage={goToPage} /> }
        {currentState === "subjects" && <CwizSubjects subjects={subjects} updateResponse={updateResponse} response={response} goToPage={goToPage} /> }
        {currentState === "tertiary" && <CwizTertiary tertiary={tertiary} updateResponse={updateResponse} response={response} goToPage={goToPage} /> }
        {currentState === "results" && <CwizResults 
            prefs={careerPrefs} 
            addCareer={addCareer}
            deleteCareer={deleteCareer}
            results={results} 
            updateResponse={updateResponse} 
            response={response} 
            goToPage={goToPage} {...props} /> }
        {currentState === "prefs" && <CareerPrefsPage prefs={careerPrefs} deletePref={deleteCareer} goToPage={goToPage} {...props} />}
        <Row className="mt-32 text-center">
            <Col>
                <small>Acknowledgement to The Good Education Group (The Good Guide and Good Universities). &copy; Copyright {new Date(Date.now()).getFullYear()}.</small>
            </Col>
        </Row>
    </ContentWrapper>
}

export default Cwiz;