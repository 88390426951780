import React from "react";
import PageSectionRow from "../../components/PageSectionRow";
import { Col } from "../../components/grid";
import { RouteComponentProps } from "react-router";
import ContentWrapper from "../../components/ContentWrapper";
import CareerGrid from "../../components/CareerGrid";

const MyCareerListing : React.FC<RouteComponentProps> = ({history} : RouteComponentProps) => 
    <ContentWrapper
            title="Explore Your Career"
            subtitle={["Select a career field to get started"]} >
        <PageSectionRow><Col>
            <CareerGrid numPerRow={4} onCareerSelect={(c)=>history.push("/myCareer/"+c.id)} />
        </Col></PageSectionRow>
    </ContentWrapper>;

export default MyCareerListing;