import React from "react";
import { Route, Switch, Redirect, RouteProps, RouteComponentProps } from "react-router-dom";
import Homepage from '../views/homepage/Homepage';
import UsefulLinks from '../views/UsefulLinks';
import Apprenticeships from '../views/Apprenticeships';
import WorkExperience from '../views/work_experience/WorkExperiencePapers';
import FindMyJobAds from '../views/FindMyJobAds';
import MySubjects from '../views/mySubjects/MySubjects';
import Earnings from '../views/Earnings';
import TAFE from '../views/TAFE';
import CareerVideos from '../views/CareerVideos';
import GapYear from '../views/uni/GapYear';
import OpenDays from '../views/uni/OpenDays';
import InterstateOverseas from '../views/uni/InterstateOverseas';
import MyAtarListing from '../views/my_atar/MyAtarListing';
import MyATARCategories from '../views/my_atar/MyAtarCategories';
import MyPreferences from '../views/my_atar/MyPreferences';
import MyCareerListing from '../views/my_career/MyCareerListing';
import MyCareerDetails from '../views/my_career/MyCareerDetails';
import ResumeSelector from '../views/plan/ResumeSelector';
import ResumeEditor from '../views/plan/ResumeEditor';
import MyNews from '../views/MyNews';
import CaCareerTable from '../views/admin/CaCareerTable';
import CaStudentNeeds from '../views/admin/CaStudentNeeds';
import CaAtarPrefs from "../views/admin/CaAtarPrefs";
import CaLeavingYearTable from "../views/admin/CaLeavingYearTable";
import CaSubjectTable from "../views/admin/CaSubjectTable";
import CaDestinationSurveyResults from '../views/admin/CaDestinationSurvey';
import CaAdminHome from '../views/admin/CaAdminHome';
import WorkExperienceListing from "../views/work_experience/eWorkExperienceListing";
import WorkExperienceQuiz from "../views/work_experience/eWorkExperienceProject";
import RegistrationPage from '../views/registration/Registration';
import StudentPage from '../views/admin/StudentPage';
import UnsubscribePage from "../views/Unsubscribe";
import SupplementaryRegistration from '../views/SupplementaryRegistration';
import Tos from '../views/Tos';
import License from '../views/License';
import RecordInterview from '../views/admin/RecordInterview';
import LegacyPlanViewer from '../views/admin/LegacyPlanViewer';
import HelpVideos from '../views/admin/HelpVideos';
import WhatToWrite from '../views/old_static_pages/Write';
import WhatToSayToABoss from '../views/old_static_pages/Boss';
import WhatToSayOnThePhone from '../views/old_static_pages/Phone';
import CommunityService from '../views/CommunityService';
import MyQuestions from '../views/old_static_pages/MyQuestions';
import CareersFacebookPage from "../views/CareersFacebookPage";
import LessonPlans from '../views/LessonPlans';
import Order from '../views/Order';
import StudentHelpVideos from '../views/StudentHelpVideos';
import ForgotPassword from '../views/ForgotPassword';
import DestinationSurvey from '../views/destination_survey';
import CaSendingRecord from '../views/admin/CaSendingRecord';
import LoginPage from '../views/LoginPage';
import CareerVR from '../views/CareerVR';
import UpdateDetails from '../views/update_details/UpdateDetails';
import ChangePassword from '../views/update_details/ChangePassword';
import UpdateSubscriptions from '../views/update_details/ChangeSubscriptions';
import TafeCourses from '../views/tafe_courses';
import TafeCourseListing from "../views/tafe_courses/CourseListing";
import UniStories from '../views/uni/UniStories';
import SchoolHomepageLayout from "../views/school_homepage/Layout";
import SchoolHomepageWrapper from '../views/school_homepage/SchoolHomepageWrapper';
import { UserDetails, UserTypes } from 'jobjump-types/User';
import { HeroConfiguration, SchoolHomepage } from "jobjump-types/SchoolHomePage";
import UniProfile from "../views/uni/UniProfile";
import UniProfileDetails from "../views/uni/UniProfileDetails";
import UniDirectory from "../views/uni/UniDirectory";
import ContactAnEmployer from "../views/old_static_pages/ContactAnEmployer";
import SoftSkills from "../views/SoftSkills";
import FirstAid from "../views/FirstAid";
import NoticeBoard from "../views/admin/NoticeBoard";
import FileManager from "../views/admin/FileManager";
import WorkSafety from "../views/WorkSafetey";
import SchoolHomePageConfig from "../views/admin/SchoolHomepageConfiguration";
import InterviewHistory from "../views/admin/InterviewHistory";
import MySchoolFiles from "../views/MySchoolFiles";
import MySchoolBulletins from "../views/MySchoolBulletins";
import Features from "../views/Features";
import Resources from "../views/admin/Resources";
import Cwiz from "../views/cwiz";
import Bullseyes from "../views/cwiz/Bullseys";
import CareerSelect from "../views/registration/CareerSelect";
import CaCwizResults from "../views/admin/CwizResults";
import ResumeListing from "../views/admin/ResumeListing";
import UniSelection from "../views/uni_select";
import StudentUniContactPrefs from "../views/admin/StudentUniContactPrefs";
import StudentProjects from "../views/admin/StudentProjects";
import WorkCheck from "../views/work_check";
import StudentPreWorkCheck from "../views/admin/StudentPreworkStatus";
import { ColdCanvassingWorkCheck, EmploymentSkillsCheck, EWorkExperienceCheck, KnowingYourCareerCheck, LiteracyAndNumeracyCheck, ResumeCheck, SoftSkillsCheck, TheInterviewCheck, WHSCheck } from "../views/work_check/WorkCheckContent";
import WorkPrepResources from "../views/admin/WorkPrepResources";
import WHSQuiz from "../views/WHSQuiz";
import KeyDate from "../views/uni/KeyDates";
import SelectResume from "../views/resume/SelectResume";
import ResumeBuilder from "../views/resume/ResumeBuilderWrapper";
import CaResumeEditor from "../views/admin/CaResumeEditor";
import SetDestinationSurvey from "../views/SetDestinationSurvey";
import WhatToSayOnThePhoneWorkExp from "../views/old_static_pages/WorkExpPhone";
import WhatToWriteWorkExp from "../views/old_static_pages/WorkExpWrite";
import WorkExperienceDirectoryListing from "../views/work_experience/WorkExperienceDirectoryListing";
import WorkExperienceDirectoryDetails from "../views/work_experience/WorkExperienceDirectoryDetails";
import ContactUniListing from "../views/my_atar/ContactUniListing";
import ContactUniCategories from "../views/my_atar/ContactUniCategories";
import SubjectDetails from "../views/mySubjects/SubjectDetails";

export type UserDetailsProps = {
    userDetails: UserDetails
}

interface RegisteredUsersProps extends RouteProps {
  userDetails? : UserDetails
  registrationTypes? : UserTypes[]
}

interface UnregisteredUsersProps extends RouteProps {
  userDetails? : UserDetails
}

interface JobJumpRoutesProps {
    userDetails?: UserDetails,
    setUserDetails: (u:UserDetails)=>void,
    schoolConfig?:SchoolHomepage,
    schoolId?:string,
    logoUrl?:string,
    heroConfig?:HeroConfiguration,
    isInSubDomain:boolean
}

const JobJumpRoutes : React.FC<JobJumpRoutesProps> = ({userDetails,setUserDetails,schoolConfig,schoolId,logoUrl,heroConfig,isInSubDomain}) => {
  const TrackedRoute: React.FC<RouteProps> =
    ({ component, ...props }) =>
      component ? <Route component={component} {...props} /> : <></>

  const RegisteredUserRoute : React.FC<RegisteredUsersProps> =
    ({userDetails, registrationTypes, ...props}) =>
      !userDetails ? 
        <LoginPage setUserDetails={setUserDetails} /> :
        // If checking for registration types and this user is in that type, or we're not looking for registrationTypes
        (registrationTypes && registrationTypes.includes(userDetails.registrationType)) || !registrationTypes ?
          <TrackedRoute {...props} />
        : <></>

  const UnregisteredUsersRoute : React.FC<UnregisteredUsersProps> =
    ({userDetails, ...props}) =>
      userDetails ?
        <Redirect to="/" />
      : <TrackedRoute {...props} />

    return <Switch>
        <TrackedRoute path="/whereis" component={()=><UsefulLinks userDetails={userDetails} />} />
        <TrackedRoute path="/unsubscribe" component={UnsubscribePage} />
        <TrackedRoute path="/suppRegistration" component={SupplementaryRegistration} />
        <TrackedRoute path="/tos" component={Tos} />
        <TrackedRoute path="/license" component={License} />
        <TrackedRoute path="/helpVideo" component={StudentHelpVideos} />
        <TrackedRoute path="/setSurveySend" component={SetDestinationSurvey} />
        <UnregisteredUsersRoute path="/register" userDetails={userDetails} 
            component={(props: RouteComponentProps) => <RegistrationPage setUserDetails={setUserDetails} {...props} />} />
        <UnregisteredUsersRoute path="/forgot_password" userDetails={userDetails} 
            component={(props: RouteComponentProps) => <ForgotPassword setUserDetails={setUserDetails} {...props} />} />
            {/* TODO: The following two routes are temporary to expose the tafe course search to everyone
            * will need to change these to RegisteredUserRoute later */}
        <TrackedRoute path="/tafe-courses/:categoryID" component={(props:RouteComponentProps<any>) => 
            <TafeCourseListing showLoggedInFeatures={userDetails !== undefined} {...props} />
        } />
        <RegisteredUserRoute userDetails={userDetails} path="/tafe-courses" component={(props:RouteComponentProps)=>userDetails?<TafeCourses userDetails={userDetails} {...props} /> : <></>} />
        <RegisteredUserRoute path="/tafe-first-nations" userDetails={userDetails} component={() => userDetails ? <TAFE userDetails={userDetails} /> : <></>} />
        <RegisteredUserRoute path="/news" component={MyNews} userDetails={userDetails} />
        <RegisteredUserRoute path="/apprenticeships" userDetails={userDetails} component={Apprenticeships} />
        <RegisteredUserRoute path="/workExperience/phone" userDetails={userDetails} component={()=>userDetails ? <WhatToSayOnThePhoneWorkExp /> : <></>} />
        <RegisteredUserRoute path="/workExperience/write" userDetails={userDetails} component={()=>userDetails ? <WhatToWriteWorkExp /> : <></>} />
        <RegisteredUserRoute path="/workExperience/papers" userDetails={userDetails} component={()=>userDetails ? <WorkExperience userDetails={userDetails} /> : <></>} />
        <RegisteredUserRoute path="/workExperience/:career" userDetails={userDetails} component={(props:RouteComponentProps<{career:string}>)=><WorkExperienceDirectoryDetails {...props} />} />
        <RegisteredUserRoute path="/workExperience/" userDetails={userDetails} component={(props:RouteComponentProps)=><WorkExperienceDirectoryListing {...props} />} />
        <RegisteredUserRoute path="/eWorkExperience/:career" userDetails={userDetails} component={WorkExperienceQuiz} />
        <RegisteredUserRoute path="/eWorkExperience" userDetails={userDetails} component={WorkExperienceListing} />
        <RegisteredUserRoute path="/careerVideos" userDetails={userDetails} component={CareerVideos} />
        <RegisteredUserRoute path="/careerVR" userDetails={userDetails} component={CareerVR} />
        <RegisteredUserRoute path="/jobs/:career?" userDetails={userDetails} component={FindMyJobAds} />
        <RegisteredUserRoute path="/subjectSelection/:id" userDetails={userDetails} component={SubjectDetails} />
        <RegisteredUserRoute path="/subjectSelection" userDetails={userDetails} component={MySubjects} /> 
        <RegisteredUserRoute path="/earnings" userDetails={userDetails} component={Earnings} />
        <RegisteredUserRoute path="/workSafety" userDetails={userDetails} component={(props:RouteComponentProps)=>userDetails ? <WorkSafety userDetails={userDetails} {...props} /> : <></>} />

        <RegisteredUserRoute path="/plan/:kind/:categoryID?/:careerID?" userDetails={userDetails} component={ResumeEditor} />
        <RegisteredUserRoute path="/plan" userDetails={userDetails} component={ResumeSelector} />

        <RegisteredUserRoute path="/resume/:id" userDetails={userDetails} component={ResumeBuilder} />
        <RegisteredUserRoute path="/resume" userDetails={userDetails} component={SelectResume} />

        <RegisteredUserRoute path="/atar/preferences" userDetails={userDetails} component={MyPreferences} />

        <RegisteredUserRoute path="/atar/scholarships/:category_id" userDetails={userDetails} component={(props:RouteComponentProps<{category_id:string}>)=>
                userDetails ? <ContactUniListing kind={"scholarships"} userDetails={userDetails} {...props} /> : <></>} />

        <RegisteredUserRoute path="/atar/scholarships/" userDetails={userDetails} component={(props:RouteComponentProps)=>
                userDetails ? <ContactUniCategories kind={"scholarships"} userDetails={userDetails} {...props} /> : <></>} />

        <RegisteredUserRoute path="/atar/plan-b/:category_id" userDetails={userDetails} component={(props:RouteComponentProps<{category_id:string}>)=>
                userDetails ? <ContactUniListing kind={"plan-b"} userDetails={userDetails} {...props} /> : <></>} />

        <RegisteredUserRoute path="/atar/plan-b/" userDetails={userDetails} component={(props:RouteComponentProps)=>
                userDetails ? <ContactUniCategories kind={"plan-b"} userDetails={userDetails} {...props} /> : <></>} />

        <RegisteredUserRoute path="/atar/:category_id" userDetails={userDetails} component={
            (props : RouteComponentProps<{category_id:string}>) => userDetails ? <MyAtarListing userDetails={userDetails} {...props} /> : <></>} />

        <RegisteredUserRoute path="/atar/" userDetails={userDetails} component={(
            props:RouteComponentProps)=>userDetails?<MyATARCategories userDetails={userDetails} {...props} /> : <></>} />

        <RegisteredUserRoute path="/myCareer/:careerID" userDetails={userDetails} component={MyCareerDetails} />
        <RegisteredUserRoute path="/myCareer/" userDetails={userDetails} component={MyCareerListing} />

        <RegisteredUserRoute path="/uni/keyDates" userDetails={userDetails} component={KeyDate} />
        <RegisteredUserRoute path="/uni/gapYear" userDetails={userDetails} component={GapYear} />
        <RegisteredUserRoute path="/uni/profiles/:name" userDetails={userDetails} component={UniProfileDetails} />
        <RegisteredUserRoute path="/uni/profiles" userDetails={userDetails} component={(props:RouteComponentProps)=>userDetails ? <UniProfile userDetails={userDetails} {...props}/>:<></>} />
        <RegisteredUserRoute path="/uni/directory" userDetails={userDetails} component={()=>userDetails ? <UniDirectory userDetails={userDetails} /> : <></>} />
        {/* This is temporarily free, needs to be RegisteredUserRoute eventually */}
        <TrackedRoute path="/uni/stories" component={UniStories} />
        <RegisteredUserRoute path="/uni/openDays" userDetails={userDetails} component={()=>userDetails ? <OpenDays userDetails={userDetails} /> : <></>} />
        <RegisteredUserRoute path="/uni/interstate" userDetails={userDetails} component={InterstateOverseas} />
        <RegisteredUserRoute path="/uni/preferences" userDetails={userDetails} component={(props:RouteComponentProps)=>userDetails ? <UniSelection userDetails={userDetails} {...props} /> : <></>} />
        <RegisteredUserRoute path="/contactEmployer" userDetails={userDetails} component={ContactAnEmployer} />
        <RegisteredUserRoute path="/whatToWrite" userDetails={userDetails} component={WhatToWrite} />
        <RegisteredUserRoute path="/meetingBoss" userDetails={userDetails} component={WhatToSayToABoss} />
        <RegisteredUserRoute path="/onThePhone" userDetails={userDetails} component={WhatToSayOnThePhone} />
        <RegisteredUserRoute path="/myQuestions" userDetails={userDetails} component={MyQuestions} />
        <RegisteredUserRoute path="/softSkills" userDetails={userDetails} component={SoftSkills} />
        <RegisteredUserRoute path="/whsQuiz" userDetails={userDetails} component={WHSQuiz} />
        <RegisteredUserRoute path="/firstAid" userDetails={userDetails} component={FirstAid} />
        <RegisteredUserRoute path="/communityService" userDetails={userDetails} component={CommunityService} />

        <RegisteredUserRoute path="/details" userDetails={userDetails} exact component={UpdateDetails} />
        <RegisteredUserRoute path="/details/password" userDetails={userDetails} exact component={ChangePassword} />
        <RegisteredUserRoute path="/details/news" userDetails={userDetails} exact component={UpdateSubscriptions} />
        <RegisteredUserRoute path="/careerSelect" userDetails={userDetails} exact component={CareerSelect} />

        <RegisteredUserRoute path="/cwiz" userDetails={userDetails} component={Cwiz} />
        <RegisteredUserRoute path="/bullseyes" userDetails={userDetails} component={Bullseyes} />

        <RegisteredUserRoute path="/workCheck/coldCall" userDetails={userDetails} component={ColdCanvassingWorkCheck} />
        <RegisteredUserRoute path="/workCheck/literacyNumeracy" userDetails={userDetails} component={LiteracyAndNumeracyCheck} />
        <RegisteredUserRoute path="/workCheck/eWorkExperience" userDetails={userDetails} component={EWorkExperienceCheck} />
        <RegisteredUserRoute path="/workCheck/knowCareer" userDetails={userDetails} component={KnowingYourCareerCheck} />
        <RegisteredUserRoute path="/workCheck/employmentSkills" userDetails={userDetails} component={EmploymentSkillsCheck} />
        <RegisteredUserRoute path="/workCheck/softSkills" userDetails={userDetails} component={SoftSkillsCheck} />
        <RegisteredUserRoute path="/workCheck/resume" userDetails={userDetails} component={ResumeCheck} />
        <RegisteredUserRoute path="/workCheck/interview" userDetails={userDetails} component={TheInterviewCheck} />
        <RegisteredUserRoute path="/workCheck/whs" userDetails={userDetails} component={WHSCheck} />
        <RegisteredUserRoute path="/workCheck" userDetails={userDetails} component={WorkCheck} />

        <TrackedRoute path="/facebook_page" component={CareersFacebookPage} />
        <RegisteredUserRoute path="/lessonPlans" userDetails={userDetails} component={LessonPlans} />
        <TrackedRoute path="/order" component={Order} />
        <TrackedRoute path="/destination" component={DestinationSurvey} />
        <TrackedRoute path="/school/files" component={()=>
            <MySchoolFiles schoolId={schoolId}  />} />
        <TrackedRoute path="/school/bulletins" component={()=>
            <MySchoolBulletins schoolConfig={schoolConfig} schoolId={schoolId}  />} />
        <RegisteredUserRoute path="/admin/homepageSetup" userDetails={userDetails} registrationTypes={["advisor"]}
            component={SchoolHomePageConfig} />
 
        <RegisteredUserRoute path="/admin/careers" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaCareerTable} />
        <RegisteredUserRoute path="/admin/resumes" userDetails={userDetails} registrationTypes={["advisor"]}
            component={ResumeListing} />
        <RegisteredUserRoute path="/admin/quiz" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaCwizResults} />
        <RegisteredUserRoute path="/admin/needs" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaStudentNeeds} />
       <RegisteredUserRoute path="/admin/preferences" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaAtarPrefs} />
        <RegisteredUserRoute path="/admin/subjects" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaSubjectTable} />
        <RegisteredUserRoute path="/admin/leavingYear" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaLeavingYearTable} />
        <RegisteredUserRoute path="/admin/help" userDetails={userDetails} registrationTypes={["advisor"]}
            component={HelpVideos} />
        <RegisteredUserRoute path="/admin/destination" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaDestinationSurveyResults} />
        <RegisteredUserRoute path="/admin/messageHistory" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaSendingRecord} />
        <RegisteredUserRoute path="/admin/noticeboard" userDetails={userDetails} registrationTypes={["advisor"]}
            component={NoticeBoard} />
        <RegisteredUserRoute path="/admin/files" userDetails={userDetails} registrationTypes={["advisor"]}
            component={FileManager} />
        <RegisteredUserRoute path="/admin/projects" userDetails={userDetails} registrationTypes={["advisor"]}
            component={StudentProjects} />
        <RegisteredUserRoute path="/admin/workPrepRes" userDetails={userDetails} registrationTypes={["advisor"]}
            component={WorkPrepResources} />
        <RegisteredUserRoute path="/admin/resources" userDetails={userDetails} registrationTypes={["advisor"]}
            component={Resources} />
        <RegisteredUserRoute path="/admin/uniContact" userDetails={userDetails} registrationTypes={["advisor"]}
            component={StudentUniContactPrefs} />
        <RegisteredUserRoute path="/admin/interviews" userDetails={userDetails} registrationTypes={["advisor"]}
            component={InterviewHistory} />
       <RegisteredUserRoute path="/admin/interview/:studentID/:interviewID?"
            userDetails={userDetails} registrationTypes={["advisor"]} component={RecordInterview} />
        <RegisteredUserRoute path="/admin/resume/:student/:id"
            userDetails={userDetails} registrationTypes={["advisor"]} component={CaResumeEditor} />
        <RegisteredUserRoute path="/admin/plan/:userID/:kind/:id?"
            userDetails={userDetails} registrationTypes={["advisor"]} component={LegacyPlanViewer} />
        <RegisteredUserRoute path="/admin/student/:studentID"
            userDetails={userDetails} registrationTypes={["advisor"]} component={StudentPage} />
        <RegisteredUserRoute path="/admin/preWorkCheck"
            userDetails={userDetails} registrationTypes={["advisor"]} component={StudentPreWorkCheck} />
        <RegisteredUserRoute path="/admin" userDetails={userDetails} registrationTypes={["advisor"]}
            component={CaAdminHome} />
        <TrackedRoute path="/schoolPage/:shortCode" component={SchoolHomepageWrapper} />
        <TrackedRoute path="/features" component={Features} />
        <TrackedRoute path="/login" component={(props:RouteComponentProps)=>{
            return userDetails ? <Homepage {...props} /> :
            <LoginPage setUserDetails={setUserDetails} />
        }} />
        <TrackedRoute path="/" component={(props:RouteComponentProps)=> {
            if (!isInSubDomain) {
            return <Homepage {...props} />
            }
            if (!schoolConfig || !schoolId) {
            return <></>;
            }
            return <SchoolHomepageLayout heroConfig={heroConfig} schoolId={schoolId} logoUrl={logoUrl} layout={schoolConfig} {...props}/>;
        }} />
        </Switch>
}

export default JobJumpRoutes;
