import React from "react";
import { Col, Row } from "../components/grid";

const Tos: React.FC<{}> =
() => <Row><Col>
    <div className="page-header">
        <h1>Terms of Service</h1>
    </div>
    <h3>Definitions</h3>
    <p>In these Terms and Condition of Use: </p>
    <ol type="a">
        <li>JJ Pty Ltd means JobJump Pty Ltd (ACN 167 907 253)</li>
        <li>Website means the website 'www.jobjump.com.au'</li>
        <li>'User' refers to a person who has access to the website pursuant to entry into the JobJump</li>
    </ol>
    <p><strong>Licence Agreement</strong> and the provision of a unique username, email address and password. If the user is a secondary or tertiary institution, the term 'User' extends to staff members, students and their parents of that secondary or tertiary institution.</p>
    <h3>Access</h3>
    <p>By accessing or using this website in any way, immediately and from your very first access of this website, you unconditionally and irrevocably agree to be bound by the Conditions of Use of our website.</p>
    <p>The 'User' will be provided with a unique Username and password. Disclosure to any other third party unless permitted otherwise is prohibited and will constitute a breach of the Terms and Conditions. We will reserve the rights in respect of any unauthorised disclosure.</p>
    <h3>Copyright Use</h3>
    <p>All content and Intellectual Property on the JobJump website is subject to copyright under the Copyright Act 1968 (Cth) and is solely owned by <strong>JobJump Pty Ltd (ACN 167 907 253)</strong> and its affiliate companies. This content includes but is not limited to, the text, information, graphics and videos. Apart from any use permitted under the Copyright Act 1968, no part may be reproduced, adapted, uploaded to a third party, linked to, framed, performed in public, distributed or transmitted in any form or by any process, nor may any other exclusive right be exercised, without the specific written permission of JobJump Pty Ltd. To obtain such permission please contact JobJump at <a href="mailto:contact@jobjump.com.au">contact@jobjump.com.au</a>. Nothing that you do on this Website will transfer or assign you any copyright in the content of the Website.  This permission does not extend to copyright in the material on this website that are owned by others but accessible on this website by way of hyperlink or reference or other means.</p>
    <p>You may copy, display, download, print and reproduce this material in unaltered form for purely educational purposes or for your personal, non-commercial use, on the condition that you include the copyright notice &copy; JobJump Pty Ltd on all uses.</p>
    <h3>Disclaimer</h3>
    <p><strong>JobJump Pty Ltd</strong> has exercised its best efforts and judgement in compiling the information on <strong>www.jobjump.com.au</strong> to ensure the information is correct, current and complete but takes no responsibility for any error, omission or defect therein. Furthermore, <strong>JobJump Pty Ltd</strong> makes no warranties as to the accuracy, completeness, timeliness or fitness for purpose of any of the information provided on the website.</p>
    <p>Furthermore, <strong>JobJump Pty Ltd</strong> makes no warranty in respect of the Website, including that it will be virus free or that access to the Website will be uninterrupted</p>
    <p>Our website www.jobjump.com.au may contain links to third-party websites, advertisers, services, special offers or other events or activities that are not owned or controlled by <strong>JobJump Pty Ltd or www.jobjump.com.au</strong>. We do not endorse or assume any responsibility for any such third-party sites, information, materials, products or services. If you access any third-party website, services or content from our website, you do so at your own risk and you agree that <strong>JobJump Pty Ltd and www.jobjump.com.au</strong> will have no liability arising from your use of or access to any third-party website, services or content.</p>
    <p>Whilst the information on this website may contain information about career options, any material or wording on this website is not, under any circumstances, intended to be, and does not, in any way constitute any professional, legal, career or other advice. The material or wording on this website must not in any way, be relied on as professional, legal, career advice. We recommend that you contact the relevant educational institution or professional or trade organisation before making any decisions about your career or future plans.</p>
    <h3>Use of Information</h3>
    <p>Any information, ideas, concepts, know-how or techniques provided by, or obtained from, you or your use of the <strong>www.jobjump.com.au</strong> website shall be deemed to be non-confidential and <strong>JobJump Pty Ltd</strong> shall be free to reproduce, use, disclose and distribute the information to others without limitation insofar as it complies with Australian Privacy Laws.</p>
    <h3>Applicable Law</h3>
    <p>This website (excluding any linked third party sites) is controlled by the <strong>JobJump Pty Ltd</strong>. By accessing this site, you accept that any disputes about this website or its contents are to be determined by the courts having jurisdiction in New South Wales in accordance with the laws in force in New South Wales (except any principle of conflict of laws inconsistent with this requirement). This website may be accessed throughout Australia and overseas. <strong>JobJump Pty Ltd</strong> makes no representation that the content of this website complies with the laws (including intellectual property laws) of any country outside Australia. If you access this site from outside Australia, you do so on your own responsibility and are responsible for ensuring compliance with all laws in the place where you are located.</p>
    <h3>Email Address</h3>
    <p>A valid email address is required to process feedback or other electronic communication from you the 'User' to our site. Email addresses supplied by Users may be retained and used only for the purpose for which they were provided.</p>
    <h3>Feedback</h3>
    <p>If the User may have any queries, suggestions or comments, they can be made by privately in writing to <a href="admin@jobjump.com.au">admin@jobjump.com.au</a>. In addition, if the User believes any information on the website or affiliated services of www.jobjump.com.au is inaccurate, the User can privately write to <a href="admin@jobjump.com.au">admin@jobjump.com.au</a> to make such suggestions</p>
    <h3>Website Privacy Statement</h3>
    <p>When a User accesses the www.jobjump.com.au website, personal identifying data and other information may be recorded. <strong>JobJump Pty Ltd</strong> will treat such information in accordance with the privacy obligations stated in the Privacy Act 1998 and the Privacy and Personal Information Protection Act 1998 and the User consents to <strong>JobJump Pty Ltd</strong> to use or disclose such information for its own purposes including to the school's careers adviser or the school's nominated careers advising delegate, and as reasonably required for the operation of the website or as otherwise required by law. The User agrees to comply with its obligations under the Act, to keep secure its log-on passwords and otherwise comply with all reasonable privacy and security requirements as requested or notified by <strong>JobJump Pty Ltd.</strong></p>
    <p>Any information that you the User freely provide on the registration page of the website namely, your name, whether you are a student or a parent/carer/guardian/support for the student, your school or child's school, or educational institution or organisation, your year of study, your geographical region, your background such as if you are from an Aboriginal or Torres Strait Islander background, the subjects that you study, your email address, the careers you have selected from the listing provided or any other such information which is sought when registering on www.jobjump.com.au is provided freely by you, the User for the following purposes:</p>
    <p><ul>
        <li>to allow <strong>JobJump Pty Ltd</strong> to send email messages to your email address based on any or all of the information you have supplied for the duration of your secondary education (if you the User are a secondary student or parent/carer/guardian/support for the student) and for a further year after the completion of this period. This period of time is to be known as the term of the registration. Note: at the end of the calendar year of the term of the registration the User's details will be removed from the website's database and no further services from <strong>JobJump Pty Ltd </strong> will be provided.</li>
        <li>to allow <strong>JobJump Pty Ltd</strong> to disclose your email address to your Careers Adviser or other nominated staff at your school for the purposes of careers related communications.</li>
        <li>to allow your Careers Adviser to contact your nominated tertiary institutions on your behalf and with your consent</li>
        <li>to gather statistical data, to monitor broad User patterns and trends, for research purposes and to improve the website's services.</li>
        <li>to email marketing material based on any or all of the information you have supplied for the term of the User's registration.</li>
    </ul></p>
    <p>Personal information you the User provide will be used for the purposes mentioned above or as otherwise permitted by Privacy legislation.</p>
    <p>You, the User, if you are a minor, will have taken measures to seek approval from your parent/carer/guardian to register your personal information as detailed above. If you subsequently learn that your parent/carer/guardian has not given you such permission, you agree to take measures to unsubscribe from the website at your earliest opportunity.</p>
    <p>You the User have the option to unsubscribe at any time during the term of registration and all information you have supplied on the registration page will be removed from the database of the website. If you elect to reregister on www.jobjump.com.au during the term of your registration, you the User are free to do so.</p>
    <p>You the User are free to alter or update any information on your registration at any time during the term of the registration or when sought from <strong>JobJump Pty Ltd</strong>, for example updating the User's senior subject selections when moving from Year 10 to Year 11.</p>
    <p>You the User, acknowledges that the website's Internet Service Provider may make records of the pages and the URLs you visit on the website, the time, date and duration of your visit. You further acknowledge and allow that such data is to be used by <strong>JobJump Pty Ltd</strong> solely for statistical and research purposes, for example, assessing times the website has differing levels of visitor traffic, assessing the geographical area of origin of Users at differing times, or the time and duration for differing traffic trends based on material and messages sent by email or posted on the website. The website may collect and use such data from the use of cookies for the purpose of measuring User traffic to the website.</p>
    <p>You, the User allow <strong>JobJump Pty Ltd</strong> to make contact with you via email, only if <strong>JobJump Pty Ltd</strong> identifies that there is a need to provide support for your registration. <strong>JobJump Pty Ltd</strong> reserves the right to contact the User should the need arise where it may affect their registration, access to the site and receipt of email news.</p>
    <p><strong>JobJump Pty Ltd</strong> is committed to protecting the privacy of your personal information. Given and not withstanding all of the above, www.jobjump.com.au has taken measures to make secure the personal information you the User supplies to the website. </p>
    <p>Any information that the User sends to the Website is sent completely at the User's own and sole risk. </p>
    <p>This Privacy Statement does not apply to third-party websites with which www.jobjump.com.au has links.</p>
    <p>In acknowledgement of having read and understood these points above and by accessing the website, you agree to the Terms and Conditions herein.</p>
    <h3>Security Statement</h3>
    <p>JobJump Pty Ltd applies best practice security controls. Below are the key ways we ensure the protection of your data.</p>
    <h4>Data Hosting</h4>
    <p>JobJump's infrastructure is hosted on Amazon Web Services (AWS). We follow AWS recommended best practice to take full advantage of their secure and fault tolerant environment.  You can find out more here: <a href="https://aws.amazon.com/security/">Cloud Security  – Amazon Web Services (AWS)</a> .</p>
    <p>All data hosted on AWS is hosted in Australian regions.</p>
    <h4>Data Encryption</h4>
    <p>All data is encrypted at rest using AES-256 encryption.</p>
    <h4>Backups</h4>
    <p>User data is automatically and routinely backed up.</p>
    <h4>Authentication</h4>
    <p>All JobJump traffic is restricted to HTTPS encrypted connections.</p>
    <h4>Employee Access Restrictions</h4>
    <p>No JobJump staff will access user personal data unless required for support or incident response.  JobJump will seek explicit consent before doing so, except where responding to an immediate security threat.</p>
    <p>When resolving support issues we do our best to respect your privacy and only access the minimum data required.</p>
    <p>Only select technical employees may access data in these cases.</p>
    <p>Employees and contractors have received security awareness training.</p>
    <h4>Credentials</h4>
    <p>All credentials are salted and encrypted</p>
    <h4>Reporting an Issue</h4>
    <p>Your input and feedback on our security is welcome. Responsible disclosure is always appreciated. If you discover a security concern, please email us at <a href="mailto:admin@jobjump.com.au">admin@jobjump.com.au</a>. We consider all security correspondence our highest priority, and are happy to work promptly with you to understand and address any issues that arise.</p>
    <h3>Trademarks</h3>
    <p>All trademarks on the website unless expressly stated otherwise, are owned by JobJump Pty Ltd and its affiliate companies and may be registered and protected in various jurisdictions. Other product names referred to on the website may also be protected trademarks.</p>
</Col></Row>

export default Tos;