import {CwizResponse} from "jobjump-types/Cwiz";
import qs from "query-string";
import { fetchWithAuth } from "./Utils";

export type CwizInfoType = {
    id:number,
    name:string,
    description?:string
}

export type CwizResult = {
    career_id:number,
    career_name:string,
    career_url:string,
    jobjump_id:number,
    count:number,
    origin: string[]
}

const Cwiz = {
    getCwizCategories : () : Promise<CwizInfoType[]> =>
        fetch("/api/quiz/get_career_categories").then(r=>r.json()),
    getCwizSubjects : () : Promise<CwizInfoType[]> =>
        fetch("/api/quiz/get_subjects").then(r=>r.json()),
    getCwizTertiary : () : Promise<CwizInfoType[]> =>
        fetch("/api/quiz/get_tertiary_options").then(r=>r.json()),
    getCwizPersonality: () : Promise<CwizInfoType[]> =>
        fetch("/api/quiz/get_personality_options").then(r=>r.json()),
    getCwizResult : (info:CwizResponse) : Promise<CwizResult[]> =>
        fetch("/api/quiz/get_result?"+qs.stringify(info)).then(r=>r.json()),
    getCwizResponse : () : Promise<CwizResponse|undefined> =>
        fetchWithAuth("/api/quiz/user_responses").then(r=>r.json()).catch(e => {return undefined}),
    saveCwizResponse : (response:CwizResponse) : Promise<void> =>
        fetchWithAuth("/api/quiz/user_responses", {
            method:"POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(response)
        }).then(r=>{})

}

export default Cwiz;